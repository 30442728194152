.App {
  text-align: justify;
  width: 100vw;
  height: 100vh;
  
  background-color: #033658;
  color: aliceblue;
}

.intro_text {
  font-weight: 800;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: larger;
  line-height: 1.2;
}

.intro_bolt {
  text-align: center;
  color: #5a4141;

}


.konva-box {
  position: fixed;
  top:0;
  left: 0;
  scale:4;  
}

.konva-box.zoomin, .konva-box.done {
  scale: 1;
  transition: scale 2s cubic-bezier(0.68, -0.55, 0.27, 1.55);
}

.fuerdich.done {
  opacity: 1;
  scale : 1;
  transition: opacity cubic-bezier(0.68, -0.55, 0.27, 1.55) 1s, scale linear 1s;
}


.fuerdich {
  font-weight:800;
  font-size:3em;
  text-align:center;
  opacity: 0;
  scale : 0;
}

.firsttyper {
  opacity: 1;
  scale: 1;
}

.firsttyper.zoomin {
  opacity: 0;
  scale : 0;
  transition: opacity cubic-bezier(0.68, -0.55, 0.27, 1.55) 3s, scale linear 3s;
}

.MuiModal-backdrop {
  backdrop-filter: blur(5px) !important;
}


.tappointer {
  position: fixed;
  rotate: 20deg;
  z-index: 5000;
  opacity: 0;
  animation: heart-pulse 1.5s 2 ease-out;
}


@keyframes heart-pulse {
  0% {
    font-size: 500%;
    transform: scale(1); /* scaling to 0 */
    opacity: 0.5;
  }
  20% {
    transform: scale(1.05);
    opacity: 0.8;
  }
  40% {
    transform: scale(1.15); /* increasing the size */
    opacity: 1;
  }
  60% {
    transform: scale(0.8); /* decreasing the size */
    opacity: 1;
  }
  80% {
    transform: scale(1.15); /* decreasing the size */
    opacity: 0.8;
  }
  100% {
    font-size: 500%;
    transform: scale(1); /* seeting back to initial size */
    opacity: 0;
  }



}