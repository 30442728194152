.katbox {
    margin-top: 15px;
}

.kattitle {

}

.onekurs {
    margin-top: 15px;
}


@media print {
    .printlogo {
        display: none;
    }
    .katbox {
        page-break-before: auto;
    }
    .kurs {
        page-break-inside:avoid;
    }


}